import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, IconButton, Menu, MenuItem, Paper, Typography } from '@material-ui/core';
import { Add as AddIcon, ArrowDownward as DownIcon, ArrowUpward as UpIcon, Delete as DeleteIcon, Error as ErrorIcon, Input as InputIcon, Menu as MenuIcon, Subject as SubjectIcon } from '@material-ui/icons';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineOppositeContent, TimelineDot } from '@material-ui/lab';
import queryString from 'query-string'
import { AddAppModal, MultiLineInput, Output } from '.';
import { run } from '../apps';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
  error: {
    border: '1px solid #CB000A',
    color: '#CB000A'
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  odd: {
    float: 'right'
  },
  even: {
    float: 'left'
  }
}));

class ExecutionError extends Error {  
  constructor (message, index) {
    super(message)
    Error.captureStackTrace(this, this.constructor);

    this.name = this.constructor.name
    this.index = index
  }

  index() {
    return this.index;
  }
}

const execute = (input, apps) => {
  if (!input) {
    return '';
  }
  return apps.reduce((acc, [type, args], idx) => {
    try {
      const aArgs = args || [];
      return run[type].run(acc, ...aArgs);
    } catch(e) {
      throw new ExecutionError(e.message, idx);
    }
  }, input);
};

export default function Pipeline() {
  const location = useLocation();
  const { c, input: initialInput } = queryString.parse(location.search);
  let initialApps = [];
  if (c) {
    try {
      initialApps = JSON.parse(c);
      initialApps.map(([app]) => {
        if (!run[app]) {
          throw new Error('Invalid application');
        }
      });
    } catch(e) {
      initialApps = [];
    }
  }
  const classes = useStyles();
  const history = useHistory();

  const [adding, setAdding] = useState(false);
  const [input, setInput] = useState(initialInput || '');
  const [output, setOutput] = useState('');
  const [apps, setApps] = useState(initialApps || []);
  const [anchorEl, setAnchorEl] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => { document.title = 'Pipelines'; }, []);

  useEffect(() => {
    try {
      const result = execute(input, apps);
      setOutput(result);
      setError(false);
    } catch(e) {
      setError(e.index);
      setOutput(`ERROR: ${e.message}`);
    }
  }, [input, apps]);

  const resetEls = () => {
    const el = [];
    for (let i=0; i<apps.length; i++) {
      el[i] = null;
    }
    setAnchorEl(el);
  };

  useEffect(resetEls, [apps]);
  useEffect(() => {
    let search = apps.length > 0 ? `?c=${JSON.stringify(apps)}` : '';
    if (initialInput) {
      search = `${search}&input=${initialInput}`;
    }
    history.replace({ pathname: '/pipelines', search})
  }, [apps, initialInput]);

  const move = (idx, dir) => {
    const newApps = [...apps];
    const item = newApps.splice(idx, 1);
    newApps.splice(idx+dir, 0, item[0]);
    setApps(newApps);
  };

  const handleClick = (event, idx) => {
    const arr = [...anchorEl];
    arr[idx] = event.currentTarget;
    setAnchorEl(arr);
  };

  const moveUp = (event, idx) => {
    move(idx, -1);
    resetEls();
  };

  const moveDown = (event, idx) => {
    move(idx, 1);
    resetEls();
  };

  const remove = (event, idx) => {
    const newApps = [...apps];
    newApps.splice(idx, 1);
    setApps(newApps);
    resetEls();
  };

  const handleClose = () => {
    resetEls();
  };

  const addAppStart = () => {
    setAdding(true);
  }

  const addApp = (app) => {
    const newApps = [...apps];
    newApps.push([app]);
    setApps(newApps);
  };

  return (
    <React.Fragment>
      <Typography variant="h4" gutterBottom>
        Pipelines
      </Typography>
			<Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} sm={6} zeroMinWidth style={{ flexShrink: 1 }}>
          <Timeline align="alternate">
            { apps.map(([app, params], idx) => {
            const { category, name } = run[app].config;
            const errored = error !== false && error === idx;

            return (
              <TimelineItem key={idx}>
                {(idx !== 0 && idx !== apps.length-1) || (
                <TimelineOppositeContent>
                  <Box mt={1}>
                    <Typography variant="h6" color="textSecondary">
                      <React.Fragment>
                        {idx !== 0 || "Input"}
                        {idx !== 0 || apps.length > 1 || " & "}
                        {idx !== apps.length-1 || "Output"}
                      </React.Fragment>
                    </Typography>
                  </Box>
                </TimelineOppositeContent>
                )}
                <TimelineSeparator>
                  <TimelineDot color={idx === 0 ? "primary" : (idx === apps.length-1 ? "secondary" : "grey")}>
                    <IconButton aria-controls="simple-menu" aria-haspopup="true" aria-label="Actions" onClick={(e) => handleClick(e, idx)} size="small" color="inherit">
                      <React.Fragment>
                        {idx !== 0 || <InputIcon />}
                        {idx !== apps.length-1 || apps.length === 1 || <SubjectIcon />}
                        {idx === apps.length-1 || idx === 0 || <DownIcon />}
                      </React.Fragment>
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl[idx] || null}
                      keepMounted
                      open={Boolean(anchorEl[idx] || null)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={(e) => moveUp(e, idx)}>
                        <UpIcon/>
                        Move Up
                      </MenuItem>
                      <MenuItem onClick={(e) => moveDown(e, idx)}>
                        <DownIcon/>
                        Move Down
                      </MenuItem>
                      <MenuItem onClick={(e) => remove(e, idx)}>
                        <DeleteIcon/>
                        Delete
                      </MenuItem>
                    </Menu>
                  </TimelineDot>
                  <React.Fragment>
                  <TimelineConnector className={idx === apps.length-2 ? classes.secondaryTail : null} />
                  </React.Fragment>
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={`${classes.paper} ${errored ? classes.error : ''}`}>
                    <React.Fragment>
                      {!errored || <ErrorIcon className={idx % 2 === 0 ? classes.odd : classes.even} color="inherit"/>}
                    </React.Fragment>
                    <Typography variant="h6" component="h1">
                      {category}
                    </Typography>
                    <Typography>{name}</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
              );
            })}
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <IconButton aria-label="Add App" color="inherit" onClick={addAppStart}>
                    <AddIcon />
                  </IconButton>
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={apps.length === 0 ? classes.paper : null}>
                  <React.Fragment>
                    {apps.length !== 0 || <Typography>Add a new app to modify input.</Typography>}
                  </React.Fragment>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
          {apps.length !== 0 || <Typography variant="body2" align="center" color="textSecondary">
            Pipelines are a way to chain more than one app together to perform multiple input manipulations. Click the + button above to get started.
          </Typography>}
        </Grid>
        <Grid item xs={12} sm={6} zeroMinWidth style={{ flexShrink: 1 }}>
          <MultiLineInput
            onChange={setInput}
            value={input}
            placeholder="Enter your input value here"
          />
          <Output
            value={output}
            label="Output"
            placeholder="The output of your pipeline will output here"
          />
        </Grid>
			</Grid>
      <AddAppModal 
        open={adding || false}
        onClose={() => setAdding(false) }
        onAdd={addApp}
      />
    </React.Fragment>
  );
}
