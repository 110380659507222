import React from 'react';
import { Typography } from '@material-ui/core';

export default function Header({ children }) {
  return (
    <Typography variant="h4" gutterBottom>
      {children}
    </Typography>
  );
}
