import React from 'react';
import Grid from '@material-ui/core/Grid';
import { MainListItem } from '.';
import Apps from '../apps';

export default function Home() {
  return (
    <Grid container spacing={3} alignItems="stretch">
      {Apps.map(({name, category, description, url, component, badge}, idx) => (
        <Grid item xs={6} sm={3} zeroMinWidth style={{ flexShrink: 1 }} key={idx}>
          <MainListItem category={category} title={name} description={description} url={url} badge={badge || null}/>
        </Grid>
      ))}
    </Grid>
  );
}
