import React from 'react';
import { Typography } from '@material-ui/core';
import { Divider } from '.';

export default function WhatsThis({ children }) {
  return (
    <React.Fragment>
      <Divider />
      <Typography variant="h5" gutterBottom>
        What&apos;s this?
      </Typography>
      <Typography variant="body1" gutterBottom>
        {children}
      </Typography>
    </React.Fragment>
  );
}

