import React from 'react';
import TextField from '@material-ui/core/TextField';

export default function MultiLineInput({ onChange, value, label, placeholder }) {
  return (
    <TextField
      multiline
      fullWidth
      label={label}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      rows={10}
      rowsMax={20}
      placeholder={placeholder}
      variant="outlined"
    />
  );
}
