import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { ErrorDisplay, ExampleInput, Header, InputSection, MultiLineInput, Output, WhatsThis } from '../components';
import InputParser, {parseInput} from '../utilities/InputParser';
import Runner from '../utilities/Runner';
import TitleSetter from '../utilities/TitleSetter';

const config = {
  name: 'XML Unescaper',
  category: 'XML',
  description: 'Unescape XML input',
  url: '/xml/unescaper',
  badge: 'new',
  component: <XmlUnescaper/>
};

const run = (input) => {
  return input
    .replace(/&apos;/g, '\'')
    .replace(/&quot;/g, '"')
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>');
};

function XmlUnescaper() {
  const location = useLocation();
  const parsed = parseInput(location);
  const [input, setInput] = useState(parsed.input || '');
  const [output, setOutput] = useState('');
  const [error, setError] = useState(false);

  useEffect(TitleSetter(config), []);
  useEffect(Runner(input, run, setError, setOutput), [input]);
  useEffect(InputParser(location, setInput), [location]);

  return (
		<React.Fragment>
      <Header>{config.name}</Header>
      <InputSection
        first={<MultiLineInput
          value={input}
          onChange={setInput}
          label="Input XML"
          placeholder="Enter your XML here"
        />}
        second={<Output 
          label="Unescaped Output"
          placeholder="Enter your XML input in the text field above"
          value={output}
          component={config}
          input={input}
        />}
        size={6}
      />

      {error === false || <ErrorDisplay 
        error={error} 
        successMessage="Your XML has been unescaped"
      />}

      <WhatsThis>
        This utility escapes an XML input string and replaces specific characters that would otherwise prevent parsing.
      </WhatsThis>

      <ExampleInput url={`${config.url}?input=%26lt%3Bdoc%26gt%3B%0A%26lt%3Bname%26gt%3BJohn%20%26amp%3B%20Daniel%26apos%3Bs%20Eatery%26lt%3B%2Fname%26gt%3B%0A%26lt%3Blocation%26gt%3BEast%20of%20%26quot%3BWest%26quot%3B%20blvd%26lt%3B%2Flocation%26gt%3B%0A%26lt%3B%2Fdov%26gt%3B`} />
		</React.Fragment>
  );
}

export default { run, config, XmlUnescaper };
