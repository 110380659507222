import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { ErrorDisplay, ExampleInput, Header, InputSection, MultiLineInput, Output, WhatsThis } from '../components';
import InputParser, {parseInput} from '../utilities/InputParser';
import Runner from '../utilities/Runner';
import TitleSetter from '../utilities/TitleSetter';

const config = {
  name: 'JSON Minifier',
  category: 'JSON',
  description: 'Minify JSON input',
  url: '/json/minifier',
  component: <JsonMinifier/>
};

const run = (input) => {
  const json = JSON.parse(input);
  return JSON.stringify(json, null, null);
};

function JsonMinifier() {
  const location = useLocation();
  const parsed = parseInput(location);
  const [input, setInput] = useState(parsed.input || '');
  const [output, setOutput] = useState('');
  const [error, setError] = useState(false);

  useEffect(TitleSetter(config), []);
  useEffect(Runner(input, run, setError, setOutput), [input]);
  useEffect(InputParser(location, setInput), [location]);

  return (
		<React.Fragment>
      <Header>{config.name}</Header>
      <InputSection
        first={<MultiLineInput
          value={input}
          onChange={setInput}
          label="Input JSON"
          placeholder="Enter your JSON code here"
        />}
        second={<Output 
          label="Minified Output"
          placeholder="Enter your JSON input in the text field above"
          value={output}
          component={config}
          input={input}
        />}
        size={6}
      />

      {error === false || <ErrorDisplay 
        error={error} 
        successMessage="Your JSON is valid"
      /> }

      <WhatsThis>
        This is a JSON minifier. It takes idented or otherwise formatted JSON as input and outputs a minified JSON document.
      </WhatsThis>

      <ExampleInput url={`${config.url}?input={"key": "value"}`} />
		</React.Fragment>
  );
}

export default { run, config, JsonMinifier };
