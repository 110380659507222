import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { ErrorDisplay, ExampleInput, Header, InputSection, MultiLineInput, Output, WhatsThis } from '../components';
import InputParser, {parseInput} from '../utilities/InputParser';
import Runner from '../utilities/Runner';
import TitleSetter from '../utilities/TitleSetter';

const config = {
  name: 'Lowercase',
  category: 'TEXT',
  description: 'Convert text to lowercase',
  url: '/text/lowercase',
  component: <TextLowercase/>
};

const run = (input) => {
  return input.toLowerCase();
};

function TextLowercase() {
  const location = useLocation();
  const parsed = parseInput(location);
  const [input, setInput] = useState(parsed.input || '');
  const [output, setOutput] = useState('');
  const [error, setError] = useState(false);

  useEffect(TitleSetter(config), []);
  useEffect(Runner(input, run, setError, setOutput), [input]);
  useEffect(InputParser(location, setInput), [location]);

  return (
		<React.Fragment>
      <Header>{config.name}</Header>
      <InputSection
        first={<MultiLineInput
          value={input}
          onChange={setInput}
          label="Input Data"
          placeholder="Enter the data to convert to lowercase here"
        />}
        second={<Output 
          label="Formatted Output"
          placeholder="Enter the data to convert to lowercase in the text field above"
          value={output}
          component={config}
          input={input}
        />}
        size={6}
      />

      {!error || <ErrorDisplay error={error} /> }

      <WhatsThis>
        This is a converter utility the converts input text to all lower case characters.
      </WhatsThis>

      <ExampleInput url={`${config.url}?input=Hello, World`} />
		</React.Fragment>
  );
}

export default { run, config, TextLowercase };
