import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { AppBar, Badge, Button, IconButton, Menu, MenuItem, Toolbar } from '@material-ui/core';
import {Face as FaceIcon } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import Apps from '../apps';

const useStyles = makeStyles({
  appbar: {
    background: 'linear-gradient(45deg, #36324A 30%, #A6659B 90%)',
    marginBottom: '1em'
  },
});

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 10,
  },
}))(Badge);

export default function Nav() {
  const classes = useStyles();

  const grouped = Apps.reduce((acc, app) => {
    if (!acc[app.category]) {
      acc[app.category] = [];
    }
    acc[app.category].push(app);
    return acc;
  }, {});

  return (
    <AppBar position="static" className={classes.appbar}>
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu" component={RouterLink} to="/">
          <span>exec</span>
          <FaceIcon />
          <span>ninja</span>
        </IconButton>
        <StyledBadge badgeContent={"NEW"} color="primary">
          <Button color="inherit" component={RouterLink} to="/pipelines">Pipelines</Button>
        </StyledBadge>
        {Object.keys(grouped).map((category, i) => (
          <PopupState key={i} variant="popover" popupId={`${category.toLowerCase()}-menu`}>
            {(popupState) => (
            <React.Fragment>
              <Button color="inherit" {...bindTrigger(popupState)}>{category}</Button>
              <Menu
                {...bindMenu(popupState)}
                getContentAnchorEl={null}
                transformOrigin={{ vertical: 'top', horizontal: "left" }}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              >
                {grouped[category].map(({name, description, url}, j) => (
                <MenuItem
                  key={j}
                  onClick={popupState.close}
                  component={RouterLink}
                  to={url}
                >
                  {name}
                </MenuItem>
                ))}
              </Menu>
            </React.Fragment>
            )}
          </PopupState>
        ))}
      </Toolbar>
    </AppBar>
  );
}


