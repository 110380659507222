import queryString from 'query-string'

const parseInput = (location) => {
  return queryString.parse(location.search);
};

export { parseInput };

export default function InputParser(location, setInput) {
  return () => {
    const parsed = parseInput(location);
    setInput(parsed.input);
  };
};
