import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { Footer, Home, Nav, NotFound, Pipeline } from './components';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Apps from './apps';

function App() {
  return (
    <Router>
      <Nav/>
      <Switch>
        {Apps.map(({url, component}, idx) => (
          <Route key={idx} path={url} render={ () => (
            <Container maxWidth="xl">
              {component}
            </Container>
          )}/>
        ))}
        <Route exact path="/pipelines">
          <Container maxWidth="xl">
            <Pipeline />
          </Container>
        </Route>
        <Route path="/">
          <Container maxWidth="md">
            <Home />
          </Container>
        </Route>
        <Route component={NotFound} />
      </Switch>
      <Box my={5}>
        <Footer />
      </Box>
    </Router>
  );
}

export default App;
