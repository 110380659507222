import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { ErrorDisplay, ExampleInput, Header, InputSection, MultiLineInput, Output, WhatsThis } from '../components';
import InputParser, {parseInput} from '../utilities/InputParser';
import Runner from '../utilities/Runner';
import TitleSetter from '../utilities/TitleSetter';

const config = {
  name: 'Base64 Decode',
  category: 'TEXT',
  description: 'Decode base64 input',
  url: '/text/base64-decode',
  component: <TextBase64Decode/>
};

const run = (input) => {
  try {
    return window.atob(input);
  } catch(e) {
    throw new SyntaxError('Your data is not properly base64 encoded');
  }
};

function TextBase64Decode() {
  const location = useLocation();
  const parsed = parseInput(location);
  const [input, setInput] = useState(parsed.input || '');
  const [output, setOutput] = useState('');
  const [error, setError] = useState(false);

  useEffect(TitleSetter(config), []);
  useEffect(Runner(input, run, setError, setOutput), [input]);
  useEffect(InputParser(location, setInput), [location]);

  return (
		<React.Fragment>
      <Header>{config.name}</Header>
      <InputSection
        first={<MultiLineInput
          value={input}
          onChange={setInput}
          label="Input Data"
          placeholder="Enter your base64-encoded data here"
        />}
        second={<Output 
          label="Formatted Output"
          placeholder="Enter your base64-encoded data in the text field above"
          value={output}
          component={config}
          input={input}
        />}
        size={6}
      />

      {!error || <ErrorDisplay error={error} /> }

      <WhatsThis>
        This is a base64 decoder utility that decodes base64-encoded data and displays the original content.
      </WhatsThis>

      <ExampleInput url={`${config.url}?input=SGVsbG8sIHdvcmxk`} />
		</React.Fragment>
  );
}

export default { run, config, TextBase64Decode };
