import React from 'react';
import { Chip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Divider } from '.';

const useStyles = makeStyles((theme) => ({
  link: {
    overflowWrap: 'break-word',
  }
}));

export default function ExampleInput({ url }) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Divider />

      <Typography variant="h5" gutterBottom>
        Validate via URL parameter
      </Typography>
      <Typography variant="body1" gutterBottom component="div">
        You can also pass an <Chip size="small" color="primary" label="input" variant="outlined" /> parameter that contains JSON to validate automatically. The value must be URL-encoded properly in order for this method to work.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Example:
      </Typography>
      <Typography variant="body2" gutterBottom className={classes.link}>
        <Link to={url}>
          https://execute.ninja{url}
        </Link>
      </Typography>

    </React.Fragment>
  );
}

