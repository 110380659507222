import React, { useState, useEffect } from 'react';
import { Grid, Slider, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { ErrorDisplay, ExampleInput, Header, InputSection, MultiLineInput, Output, WhatsThis } from '../components';
import InputParser, {parseInput} from '../utilities/InputParser';
import Runner from '../utilities/Runner';
import TitleSetter from '../utilities/TitleSetter';

const config = {
  name: 'JSON Formatter',
  category: 'JSON',
  description: 'Format JSON input',
  url: '/json/formatter',
  component: <JsonFormatter/>
};

const run = (input, indent=2) => {
  const json = JSON.parse(input);
  return JSON.stringify(json, null, indent);
};

function JsonFormatter() {
  const location = useLocation();
  const parsed = parseInput(location);
  const [input, setInput] = useState(parsed.input || '');
  const [output, setOutput] = useState('');
  const [error, setError] = useState(false);
  const [indent, setIndent] = useState(2);

  useEffect(TitleSetter(config), []);
  useEffect(Runner(input, run, setError, setOutput, indent), [input, indent]);
  useEffect(InputParser(location, setInput), [location]);

  return (
		<React.Fragment>
      <Header>{config.name}</Header>
      <Grid container spacing={1} alignItems="stretch">
        <Grid item xs={12} sm={6}>
          <Typography gutterBottom>Indent Level</Typography>
          <Slider
            aria-labelledby="indent-level"
            valueLabelDisplay="auto"
            getAriaValueText={() => "fart"}
            value={indent}
            onChange={(_, v) => setIndent(v)}
            step={1}
            marks
            min={1}
            max={8}
          />
        </Grid>
      </Grid>
      <InputSection
        first={<MultiLineInput
          value={input}
          onChange={setInput}
          label="Input JSON"
          placeholder="Enter your JSON code here"
        />}
        second={<Output 
          label="Formatted Output"
          placeholder="Enter your JSON input in the text field above"
          value={output}
          component={config}
          input={input}
        />}
        size={6}
      />

      {error === false || <ErrorDisplay 
        error={error} 
        successMessage="Your JSON is valid"
      /> }

      <WhatsThis>
        This is a JSON formatter/JSON beautifier. It takes minified or otherwise poorly formatted JSON as input and outputs a consistent, indented JSON document.
      </WhatsThis>

      <ExampleInput url={`${config.url}?input={"key":"value"}`} />
		</React.Fragment>
  );
}

export default { run, config, JsonFormatter };
