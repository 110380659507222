import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { ErrorDisplay, ExampleInput, Header, InputSection, MultiLineInput, Output, WhatsThis } from '../components';
import InputParser, {parseInput} from '../utilities/InputParser';
import Runner from '../utilities/Runner';
import TitleSetter from '../utilities/TitleSetter';

const config = {
  name: 'JSON Escaper',
  category: 'JSON',
  description: 'Escape JSON input',
  url: '/json/escaper',
  component: <JsonEscaper/>
};

const run = (input) => {
  return input
    .replace(/[\\]/g, '\\\\')
    .replace(/["]/g, '\\"')
    .replace(/[/]/g, '\\/')
    .replace(/[\b]/g, '\\b')
    .replace(/[\f]/g, '\\f')
    .replace(/[\n]/g, '\\n')
    .replace(/[\r]/g, '\\r')
    .replace(/[\t]/g, '\\t');
};

function JsonEscaper() {
  const location = useLocation();
  const parsed = parseInput(location);
  const [input, setInput] = useState(parsed.input || '');
  const [output, setOutput] = useState('');
  const [error, setError] = useState(false);

  useEffect(TitleSetter(config), []);
  useEffect(Runner(input, run, setError, setOutput), [input]);
  useEffect(InputParser(location, setInput), [location]);

  return (
		<React.Fragment>
      <Header>{config.name}</Header>
      <InputSection
        first={<MultiLineInput
          value={input}
          onChange={setInput}
          label="Input JSON"
          placeholder="Enter your JSON code here"
        />}
        second={<Output 
          label="Escaped Output"
          placeholder="Enter your JSON input in the text field above"
          value={output}
          component={config}
          input={input}
        />}
        size={6}
      />

      {error === false || <ErrorDisplay 
        error={error} 
        successMessage="Your string has been escaped"
      /> }

      <WhatsThis>
        This utility escapes an input JSON string and replaces specific characters that would otherwise prevent parsing.
      </WhatsThis>

      <ExampleInput url={`${config.url}?input=%7B%0A%22key%22%3A%20%22value%22%0A%7D`} />
		</React.Fragment>
  );
}

export default { run, config, JsonEscaper };
