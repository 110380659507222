import React from 'react';
import CsvParser from './CsvParser';
import JsonEscaper from './JsonEscaper'
import JsonFormatter from './JsonFormatter'
import JsonMinifier from './JsonMinifier'
import JsonUnescaper from './JsonUnescaper'
import JsonValidator from './JsonValidator'
import TextBase64Encode from './TextBase64Encode'
import TextBase64Decode from './TextBase64Decode'
import TextUrlEncode from './TextUrlEncode'
import TextUrlDecode from './TextUrlDecode'
import TextLowercase from './TextLowercase'
import TextUppercase from './TextUppercase'
import TextTitleCase from './TextTitleCase'
import XmlUnescaper from './XmlUnescaper';

const apps = [
  CsvParser,
  XmlUnescaper,
  JsonEscaper,
  JsonUnescaper,
  JsonFormatter,
  JsonMinifier,
  JsonValidator,
  TextBase64Encode,
  TextBase64Decode,
  TextUrlEncode,
  TextUrlDecode,
  TextLowercase,
  TextUppercase,
  TextTitleCase,
];

const name = (app) => {
  const { category, name } = app.config;
  const appName = name.replace(/ /g, '');
  return `${category}.${appName}`;
};

const run = apps.reduce((acc, app) => {
  const { config } = app;
  if (!config.hasOwnProperty('pipeline') || config.pipeline) {
    acc[name(app)] = app;
  }
  return acc;
}, {});

export { run };

const configs = apps.reduce((acc, app) => {
  acc.push({...app.config, run: name(app)});
  return acc;
}, []);

export default configs;
