import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Apps from '../apps';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '0px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    minWidth: 120,
  },
}));

export default function AddAppModal({ open, onClose, onAdd }) {
  const classes = useStyles();

  const pipelineApps = Apps.reduce((acc, app) => {
    if (!app.hasOwnProperty('pipeline') || app.pipeline) {
      acc.push(app);
    }
    return acc;
  }, []);
  const [app, setApp] = useState(null);
  const [modalStyle] = React.useState(getModalStyle);

  useEffect(() => {
    if (app) {
      onAdd(app);
      onClose();
      setApp(null);
    }
  }, [app, onAdd, onClose]);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="add-modal-title">Add App to Pipeline</h2>
      <p id="add-modal-description">
        Add a new app to your pipeline that will further manipulate your inputs.
      </p>
      <FormControl fullWidth variant="outlined" className={classes.formControl}>
        <Autocomplete
          id="select-app"
          options={pipelineApps}
          groupBy={(option) => option.category}
          getOptionLabel={(option) => option.name}
          style={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Select App" variant="outlined" />}
          value={app}
          onChange={(e, app) => { setApp(app.run) }}
          getOptionSelected={(option, value) => value.name === option.name}
        />
      </FormControl>
      <Button variant="outlined" onClick={onClose}>
        Close
      </Button>
      <AddAppModal />
    </div>
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="add-modal-title"
      aria-describedby="add-modal-description"
    >
      {body}
    </Modal>
  );
}
