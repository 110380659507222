import React from 'react';
import { Box, Chip } from '@material-ui/core';
import { Error as ErrorIcon, CheckCircle as CheckIcon } from '@material-ui/icons';

export default function ErrorDisplay({ error, successMessage }) {
  return (
    <React.Fragment>
      <Box my={2}/>
      <Chip
        icon={error ? <ErrorIcon /> : <CheckIcon />}
        label={error || successMessage}
        color={error ? 'secondary' : 'primary'}
      />
    </React.Fragment>
  );
}
