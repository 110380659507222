import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { useLocation } from 'react-router-dom';
import { Readable } from 'stream';
import * as CSV from 'csv-string';
import { ErrorDisplay, ExampleInput, Header, InputSection, MultiLineInput, Output, WhatsThis } from '../components';
import InputParser, {parseInput} from '../utilities/InputParser';
import Runner from '../utilities/Runner';
import TitleSetter from '../utilities/TitleSetter';

const config = {
  name: 'CSV Parser',
  category: 'CSV',
  description: 'Parse CSV input',
  url: '/csv/parser',
  badge: 'new',
  pipeline: false,
  component: <CsvParser/>
};

const StyledDataGrid = styled(DataGrid)`
  .MuiDataGrid-cell:hover {
    text-overflow: initial;
    overflow: auto;
  }
`;

const run = (input) => {
  const rows = [];
  CSV.forEach(input, CSV.detect(input), (row, index) => {
    rows.push(row.map((r) => {
      return typeof r === 'string' ? r.trim() : r;
    }));
  });
  return JSON.stringify(rows);
};

function CsvParser() {
  const location = useLocation();
  const parsed = parseInput(location);
  const [input, setInput] = useState(parsed.input || '');
  const [output, setOutput] = useState('');
  const [error, setError] = useState(false);
  const idField = { field: 'id', headerName: 'Row', width: 70 };
  const [cols, setCols] = useState([idField]);
  const [rows, setRows] = useState([
  ]);

  useEffect(TitleSetter(config), []);
  useEffect(Runner(input, run, setError, setOutput), [input]);
  useEffect(InputParser(location, setInput), [location]);

  useEffect(() => {
    if (!output) {
      return;
    }

    const [headers, ...rest] = JSON.parse(output);
    if (headers && headers.length > 0) {
      setCols(headers.reduce((acc, h) => {
        acc.push({ field: h, headName: h, width: 200 });
        return acc;
      }, [idField]));
    }
    if (rest && rest.length > 0) {
      setRows(rest.reduce((acc, r, rIdx) => {
        if (r.length === 1 && !r[0]) {
          return acc;
        }
        const o = {id: rIdx};
        r.forEach((rv, idx) => {
          o[headers[idx]] = rv;
        });
        acc.push(o);
        return acc;
      }, []));
    }
  }, [output]);

  return (
		<React.Fragment>
      <Header>{config.name}</Header>
      <InputSection
        first={<MultiLineInput
          value={input}
          onChange={setInput}
          label="Input CSV"
          placeholder="Enter your CSV data here"
        />}
        second={<div style={{ height: 400, width: '100%' }}>
          <StyledDataGrid
            rows={rows}
            columns={cols}
            pageSize={5}
          />
        </div>}
        size={12}
      />

      {error === false || <ErrorDisplay 
        error={error} 
        successMessage="Your CSV data has been processed"
      />}

      <WhatsThis>
        This utility parses a CSV document and displays it in an interactable data grid.
      </WhatsThis>

      <ExampleInput url={`${config.parsedurl}?input=%7B%0A%22key%22%3A%20%22value%22%0A%7D`} />
		</React.Fragment>
  );
}

export default { run, config, CsvParser };
