import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Card, CardActions, CardContent, Badge, Button, Typography } from '@material-ui/core';
import { PlayCircleFilled as PlayIcon } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    minHeight: 180,
  },
  title: {
    fontSize: 14,
  },
  description: {
    minHeight: 0,
  },
  csv: {
    background: 'linear-gradient(180deg, #2A8FBD 2%, #f1f1f1 3%)',
  },
  json: {
    background: 'linear-gradient(180deg, #A6659B 2%, #f1f1f1 3%)',
  },
  text: {
    background: 'linear-gradient(180deg, #BAD62B 2%, #f1f1f1 3%)',
  },
  xml: {
    background: 'linear-gradient(180deg, #B10C43 2%, #f1f1f1 3%)',
  },
});

const StyledBadge = withStyles((theme) => ({
  badge: {
    top: 2,
    right: -30,
  },
}))(Badge);

export default function MainListItem({ category, title, description, url, badge }) {
  const classes = useStyles();

  return (
    <Card className={`${classes.root} ${classes[category.toLowerCase()]}`}>
      <CardContent>
        <StyledBadge badgeContent={badge || null} color="primary">
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            {category}
          </Typography>
        </StyledBadge>
        <Typography variant="h5" component="h2">
          {title}
        </Typography>
        <Typography variant="body2" component="p" className={classes.description}>
          {description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" variant="outlined" startIcon={<PlayIcon />} component={RouterLink} to={url} fullWidth={true}>Open App</Button>
      </CardActions>
    </Card>
  );
}
