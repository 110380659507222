import React from 'react';
import { Alert } from '@material-ui/lab';
import { Button, Typography } from '@material-ui/core';
import { Home as HomeIcon, Warning as WarningIcon } from '@material-ui/icons/Home';
import { Link as RouterLink } from 'react-router-dom';

export default function NotFound() {
  return (
    <Alert variant="outlined" severity="warning">
      <Typography variant="h4" component="h4" gutterBottom>
        404: Not Found
      </Typography>
      The page you are looking for does not exist or has been removed.
      <br/><br/>
      <Button size="large" color="primary" variant="outlined" component={RouterLink} to="/">
        Go to Homepage
      </Button>
    </Alert>
  );
}
