import React from 'react';
import { Grid } from '@material-ui/core';

export default function InputSection({ first, second, size }) {
  let secondComponent = '';
  if (second) {
    secondComponent = <Grid item xs={12} md={size}>{second}</Grid>;
  }
  return (
    <Grid container spacing={1} alignItems="stretch">
      <Grid item xs={12} md={size}>{first}</Grid>
      {secondComponent}
    </Grid>
  );
}
