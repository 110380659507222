export default function Runner(input, run, setError, setOutput, ...args) {
  return () => {
    async function doRun() {
      setOutput && setOutput('');
      if (!input) {
        setError(false);
        return;
      }

      try {
        const output = await run(input, ...args);
        setOutput && setOutput(output);
        setError('');
      } catch (e) {
        setError(`${e.name}: ${e.message}`);
      }
    }
    doRun();
  };
};
