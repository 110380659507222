import React from 'react';
import { Box, Divider as MaterialDivider } from '@material-ui/core';

export default function Divider() {
  return (
    <React.Fragment>
      <Box my={4}/>
      <MaterialDivider variant="middle" />
      <Box my={2}/>
    </React.Fragment>
  );
}

