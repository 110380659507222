import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { ErrorDisplay, ExampleInput, Header, InputSection, MultiLineInput, Output, WhatsThis } from '../components';
import InputParser, {parseInput} from '../utilities/InputParser';
import Runner from '../utilities/Runner';
import TitleSetter from '../utilities/TitleSetter';

const config = {
  name: 'URL Encode',
  category: 'TEXT',
  description: 'URL encode input strings',
  url: '/text/url-encode',
  component: <TextUrlEncode/>
};

const run = (input) => {
  return encodeURIComponent(input);
};

function TextUrlEncode() {
  const location = useLocation();
  const parsed = parseInput(location);
  const [input, setInput] = useState(parsed.input || '');
  const [output, setOutput] = useState('');
  const [error, setError] = useState(false);

  useEffect(TitleSetter(config), []);
  useEffect(Runner(input, run, setError, setOutput), [input]);
  useEffect(InputParser(location, setInput), [location]);

  return (
		<React.Fragment>
      <Header>{config.name}</Header>
      <InputSection
        first={<MultiLineInput
          value={input}
          onChange={setInput}
          label="Input Data"
          placeholder="Enter the data to URL encode here"
        />}
        second={<Output 
          label="Formatted Output"
          placeholder="Enter the data to URL encode in the text field above"
          value={output}
          component={config}
          input={input}
        />}
        size={6}
      />

      {!error || <ErrorDisplay error={error} /> }

      <WhatsThis>
        This is a URL encoder utility that encodes input data and displays the URL-encoded version of that data.
      </WhatsThis>

      <ExampleInput url={`${config.url}?input=%3Fkey%3Dvalue`} />
		</React.Fragment>
  );
}

export default { run, config, TextUrlEncode };
