import React, { useState } from 'react';
import { Button, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExitToApp } from '@material-ui/icons';
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  spacer: {
    marginTop: theme.spacing(1)
  },
}));

export default function Output({ value, label, placeholder, component, input }) {
  const classes = useStyles();
  const [redirect, setRedirect] = useState(null);

  const pipe = () => {
    const { category, name } = component;
    const appName = name.replace(/ /g, '');
    const componentName = `${category}.${appName}`;
    const encoded = encodeURIComponent(input);
    setRedirect(`/pipelines?c=[["${componentName}"]]&input=${encoded}`);
  };

  return (
    <React.Fragment>
      { !redirect || <Redirect to={redirect} /> }
      <TextField
        multiline
        fullWidth
        label={label}
        value={value}
        rows={10}
        rowsMax={20}
        placeholder={placeholder}
        InputProps={{
          readOnly: true,
        }}
        variant="filled"
        readOnly
      />
      {!component || (
        <Grid container justify="center" className={classes.spacer}>
          <Button variant="outlined" color="primary" endIcon={<ExitToApp/>} onClick={pipe}>
            Pipe Output to Another App
          </Button>
        </Grid>
      )}
    </React.Fragment>
  );
}
