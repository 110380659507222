import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { ErrorDisplay, ExampleInput, Header, InputSection, MultiLineInput, WhatsThis } from '../components';
import InputParser, {parseInput} from '../utilities/InputParser';
import Runner from '../utilities/Runner';
import TitleSetter from '../utilities/TitleSetter';

const config = {
  name: 'JSON Validator',
  category: 'JSON',
  description: 'Validate JSON input',
  url: '/json/validator',
  component: <JsonValidator/>
};

const run = (input) => {
  JSON.parse(input);
  return input;
};

function JsonValidator() {
  const location = useLocation();
  const parsed = parseInput(location);
  const [input, setInput] = useState(parsed.input || '');
  const [error, setError] = useState(false);

  useEffect(TitleSetter(config), []);
  useEffect(Runner(input, run, setError), [input]);
  useEffect(InputParser(location, setInput), [location]);

  return (
		<React.Fragment>
      <Header>{config.name}</Header>
      <InputSection
        first={<MultiLineInput
          value={input}
          onChange={setInput}
          label="Input JSON"
          placeholder="Enter your JSON code here"
        />}
        size={12}
      />

      {error === false || <ErrorDisplay 
        error={error} 
        successMessage="Your JSON is valid"
      /> }

      <WhatsThis>
        It&apos;s critical in applications that consume JSON documents that the JSON document is valid. This includes things like making sure all keys and string properties are wrapped in double quotes and that commas are properly separating keys.
      </WhatsThis>

      <ExampleInput url={`${config.url}?input={"key":"value"}`} />
		</React.Fragment>
  );
}

export default { run, config, JsonValidator };
