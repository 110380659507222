import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { ErrorDisplay, ExampleInput, Header, InputSection, MultiLineInput, Output, WhatsThis } from '../components';
import InputParser, {parseInput} from '../utilities/InputParser';
import Runner from '../utilities/Runner';
import TitleSetter from '../utilities/TitleSetter';

const config = {
  name: 'JSON Unescaper',
  category: 'JSON',
  description: 'Unescape JSON input',
  url: '/json/unescaper',
  component: <JsonUnescaper/>
};

const run = (input) => {
  return input
    .replace(/\\t/g, '\t')
    .replace(/\\r/g, '\r')
    .replace(/\\n/g, '\n')
    .replace(/\\f/g, '\f')
    .replace(/\\b/g, '\b')
    .replace(/\\\//g, '/')
    .replace(/\\"/g, '"')
    .replace(/\\\\/g, '\\');
};

function JsonUnescaper() {
  const location = useLocation();
  const parsed = parseInput(location);
  const [input, setInput] = useState(parsed.input || '');
  const [output, setOutput] = useState('');
  const [error, setError] = useState(false);

  useEffect(TitleSetter(config), []);
  useEffect(Runner(input, run, setError, setOutput), [input]);
  useEffect(InputParser(location, setInput), [location]);

  return (
		<React.Fragment>
      <Header>{config.name}</Header>
      <InputSection
        first={<MultiLineInput
          value={input}
          onChange={setInput}
          label="Input JSON"
          placeholder="Enter your JSON code here"
        />}
        second={<Output 
          label="Unescaped Output"
          placeholder="Enter your JSON input in the text field above"
          value={output}
          component={config}
          input={input}
        />}
        size={6}
      />

      {error === false || <ErrorDisplay 
        error={error} 
        successMessage="Your JSON input has been unescaped"
      />}

      <WhatsThis>
        This utility unescapes an input JSON string and replaces the escaped characters with their original values.
      </WhatsThis>

      <ExampleInput url={`${config.url}?input=%7B%5Cn%5C%5C%22key%5C%5C%22%3A%20%5C%5C%22value%5C%5C%22%5Cn%7D`} />
		</React.Fragment>
  );
}

export default { run, config, JsonUnescaper };
