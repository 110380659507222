import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { ErrorDisplay, ExampleInput, Header, InputSection, MultiLineInput, Output, WhatsThis } from '../components';
import InputParser, {parseInput} from '../utilities/InputParser';
import Runner from '../utilities/Runner';
import TitleSetter from '../utilities/TitleSetter';

const config = {
  name: 'Title Case',
  category: 'TEXT',
  description: 'Convert text to Title Case',
  url: '/text/title-case',
  component: <TextTitleCase/>
};

const run = (input) => {
  const parts = input.trim().toLowerCase().split(' ');
  for(let i=0; i<parts.length; i++){
    parts[i] = parts[i][0].toUpperCase() + parts[i].slice(1);
  }
  return parts.join(' ');
};

function TextTitleCase() {
  const location = useLocation();
  const parsed = parseInput(location);
  const [input, setInput] = useState(parsed.input || '');
  const [output, setOutput] = useState('');
  const [error, setError] = useState(false);

  useEffect(TitleSetter(config), []);
  useEffect(Runner(input, run, setError, setOutput), [input]);
  useEffect(InputParser(location, setInput), [location]);

  return (
		<React.Fragment>
      <Header>{config.name}</Header>
      <InputSection
        first={<MultiLineInput
          value={input}
          onChange={setInput}
          label="Input Data"
          placeholder="Enter the data to convert to title case here"
        />}
        second={<Output 
          label="Formatted Output"
          placeholder="Enter the data to convert to title case in the text field above"
          value={output}
          component={config}
          input={input}
        />}
        size={6}
      />

      {!error || <ErrorDisplay error={error} /> }

      <WhatsThis>
        This is a converter utility the converts input text to all title case.
      </WhatsThis>

      <ExampleInput url={`${config.url}?input=hello, world`} />
		</React.Fragment>
  );
}

export default { run, config, TextTitleCase };
